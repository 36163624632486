.form-container {
  max-width: 600px;
  margin: 0 auto;
}

.form-container label {
  display: block;
  margin-bottom: 5px;
}

.form-container input[type="text"],
.form-container input[type="number"],
.form-container select,
.form-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-container input[type="range"] {
  width: 100%;
  margin-bottom: 15px;
}

.form-container button[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-container button[type="submit"]:hover {
  background-color: #45a049;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.user-info-box {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.user-info-box h2 {
  margin-bottom: 15px;
}

.TravelPlanecss {
  max-width: 800px;
  margin: 20px auto;
}

.TravelPlanecss h2 {
  margin-bottom: 15px;
}

.plan {
  margin-bottom: 20px;
}

.plan img {
  max-width: 100%;
  margin-bottom: 10px;
}
