/* Base styles */

.form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 50px;
    border: 1px solid #ccc;
    border-radius: 20px;
    background-color: #cce3ef;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .form-container {
        max-width: 90%;
        padding: 30px;
    }
}

/* Adjustments for smaller screens */
@media screen and (max-width: 480px) {
    .form-container {
        padding: 20px;
    }
}

.label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="text"],
input[type="range"],
select {
    width: 100%;
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

input[type="range"] {
    width: calc(82% - 40px);
    margin-right: 10px;
}

button[type="submit"] {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: grid;
}

button[type="submit"]:hover {
    background-color: #45a049;
}

ul.suggestions {
    list-style: none;
    padding: 0;
    margin: 5px 0;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 4px;
}

ul.suggestions li {
    padding: 8px 12px;
    cursor: pointer;
}

ul.suggestions li:hover {
    background-color: #ddd;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content.show {
    display: block;
}

.selected-items {
    margin-top: 10px;
}

.error-message {
    color: #f00;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Newly added styles */

.user-info-box {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 20px;
    background-color: #f1f7f2;
    text-align: center;
}

.user-info-box h2 {
    font-size: 1.2em;
    margin-bottom: 10px;
    font-weight: bold;
    text-transform:capitalize;
}

.user-info-box h1 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.user-info-box p {
    margin: 5px 0;
    text-transform: uppercase;
}

/* Basic button styles */

.generate-plan-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Hover effect */
.generate-plan-button:hover {
    background-color: #0056b3;
}

/* Active/focus effect */
.generate-plan-button:active,
.generate-plan-button:focus {
    outline: none;
    background-color: #0056b3;
}
/* //travel */

/* Styles for the TravelPlanecss class */
.TravelPlanecss {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 3%; /* Adjusted for responsiveness */
    margin-bottom: 2%; /* Adjusted for responsiveness */
    /* margin-left: 21%; Adjusted for responsiveness */
    /* margin-right: -14%; Adjusted for responsiveness */
    background-color: #ecf4f8;
}

/* Styles for headings */
.TravelPlanecss h2 {
    margin-top: 0;
    font-size: 1.5em;
    text-align: center;
    color: #4e3987;
}

.TravelPlanecss h1 {
    font-size: 2.5em; /* Adjusted for responsiveness */
    color: #a1640d;
}

/* Styles for days */
.TravelPlanecss h3 {
    margin-top: 1em; /* Adjusted for responsiveness */
    font-size: 1.2em;
    font-weight: bold;
}

/* Styles for attraction details */
.TravelPlanecss div {
    margin-top: 1em;
}

/* Styles for attraction details paragraphs */
.TravelPlanecss p {
    margin: 0.5em 0;
    color: #1e6e31;
}

/* Styles for images */
.plan {
    margin-left: 5%; /* Adjusted for responsiveness */
    margin-right: 5%; /* Adjusted for responsiveness */
    display: block;
    text-align: center;
}

.plan img {
    width: 100%; /* Adjusted for responsiveness */
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}


/* hotel */
.Hotel {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin-top: 20px;
    background-color: #f5e3e3;
    text-align: center;
}
.hotelnamebox{
    /* border: 1px solid #d16767; */
    border: 1px solid #353030;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 66px;
    justify-content: end;
    align-items: center;
    align-content: space-around;

}

.Hotel h2 {
    margin-top: 0;
    font-size: 1.5em;
    color: #4e3987;
}

.Hotel h3 {
    margin-top: 0.5em;
    font-size: 1.2em;
    font-weight: bold;
}
.Hotel h4 {
    margin-top: 0.5em;
    font-size: 1.2em;
    font-weight: bold;
}

.Hotel p {
    margin: 0.5em 0;
    color: #1e6e31;
}


/* food  */

.bestfood-container {
    background-color: #f1d5a5;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    /* margin: 80px; */
  }
  
  .bestfood {
    margin: 0;
  }
  
  .bestfood h2 {
    margin-bottom: 23px;
    font-size: 24px;
    color: #333;
    text-align: center;
  }
  
  .food-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
    justify-content: center; /* Align items horizontally in the center */
    align-items: center; /* Align items vertically in the center */
  }
  
  .food-item {
    background-color: #dcec96;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .food-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .food-details {
    padding: 10px;
  }
  
  .food-details h4 {
    margin: 0;
    font-size: 18px;
  }
  
  .food-details p {
    margin: 5px 0;
  }
  
  .no-recommendations {
    color: #999;
    font-style: italic;
  }
  
  .icon {
    color: #FFC107;
    margin-right: 5px;
  }
  .food-details {
    display: none;
  }
  
  .food-item:hover .food-details {
    display: block;
  }
