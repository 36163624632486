.contact-section {
    text-align: center;
  }
  
  .contact-section h2 {
    margin-bottom: 0px;
  }
  
  .contact-section input,
  .contact-section textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .contact-section button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  .contact-section button:hover {
    background-color: #0056b3;
  }
  