.about-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .team-members {
    display: flex;
    justify-content: space-between;
  }
  
  .team-member {
    flex: 0 1 45%;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  .team-member h3 {
    margin-top: 0;
  }
  
  .team-member p {
    margin-bottom: 0;
  }
  